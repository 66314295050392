import React from 'react';
import { useEffect, useState } from "react";
import "./ContactUs.css";
import anime from "animejs";
import Img2 from '../assets/Loc.png'
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import validator from 'validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles((theme) => ({

  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },


}));

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 15) {
    errors.name = 'Must be 15 characters or less';
  }

  if (!values.message) {
    errors.message= 'Required';
  } else if (values.message.length < 10) {
    errors.message = 'Must contain atleast 10 characters';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!validator.isInt(values.phone) ) { 
    errors.phone= 'Phone number must only contain numbers';
  }
  else if(values.phone.length!==10){
    errors.phone="Phone number should contain 10 digits"
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.location) {
    errors.location= 'Required';
  }


  return errors;
};


const ContactUs = () => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [dialogMessage,setdialogMessage]=React.useState('')

  const formik = useFormik({
         initialValues: {
           name: '',
           phone: '',
           email: '',
           location:'',
           message :''
         },
         validate,
         onSubmit: values => {
          
          
          fetch('https://j7obv3e358.execute-api.us-east-1.amazonaws.com/default/makeup-contactus', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-api-key' : '4GEfwRIHCq5aiwqz2ZLQ46INzW6LAYHU1pJkUTGf'
          
          
        },
       body: JSON.stringify(values)
      }) .then((response)=>response.json())
          .then(responsejson=>{
            
            if(responsejson=='success'){
             
              setdialogMessage("Query Submitted Successfully ")
              setOpen(true);
      
            }
            else{
             
              setdialogMessage("Please check the details and try again")
              setOpen(true);
            }
          })
         },
       });



  return (
    <div>
     <h1 style={{ color: 'white'}}>About Us</h1>
<div className="intro-logo jumbo-bg">
        <h1>Contact Us</h1>
        </div>
        <div>
            <text style={{fontSize: '105%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>For Makeup related queries, Call : +91 8287623582</text><br/>
            <text style={{fontSize: '105%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>Whatsapp : + 91 8010387436</text><br/>
            <text style={{fontSize: '105%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>E-Mail : makeupbypriya14@gmail.com</text><br/>
            <text style={{fontSize: '105%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>Delhi Address: 162, Bahawalpur CGHS society, sector 4,Dwarka, Delhi-110077</text><br/>
            <text style={{fontSize: '105%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>Patna Address: MIG-22, Hanuman Nagar, kankarbagh, Patna-800020</text>

        </div>
        <div style={{marginTop:'5%', marinBottom: '5%'}}>
        <a href="https://www.google.com/maps/place/The+Bahawal+Pur+Co-operative+Society/@28.5990319,77.0511112,17z/data=!3m1!4b1!4m5!3m4!1s0x390d1ad6d65f4029:0xdd40b209bfc628ff!8m2!3d28.5990319!4d77.0511112?hl=en-IN">
        <img class="entry-img"  src={Img2} alt="Priya Chandra" style={{width: '60%', height: '50%'}}/>
        </a>
        </div>

        <div style={{marginTop:'5%',margin: '10%',marinBottom: '5%'}}>
        <form onSubmit={formik.handleSubmit}>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="name" name="name" type="text" placeholder="Name" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.name}/>
                        {formik.touched.name && formik.errors.name ? (
                         <div>{formik.errors.name}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="email" name="email" type="text" placeholder="Email" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.email}/>
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="phone" name="phone" type="text" placeholder="Phone" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.phone}/>
                        {formik.touched.phone && formik.errors.phone ? (
                          <div>{formik.errors.phone}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" type="text" name="location" id="location" placeholder="Location" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.location}/>
                        {formik.touched.location && formik.errors.location ? (
                          <div>{formik.errors.location}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mt-3">
                        <input class="form-control" id="message" name="message" type="text" placeholder="Message" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.message}/>
                        {formik.touched.message && formik.errors.message ? (
                          <div>{formik.errors.message}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <button type="submit" class="btn btn-danger-gradiant mt-3 mb-3  border-0 py-2 px-3"><span> SUBMIT NOW <i class="ti-arrow-right"></i></span></button>
                    </div>
                  </div>
                </form>
                </div>                   

      

        <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}} color="primary">
            Okay
          </Button>
          </DialogActions>
      </Dialog>
        </div>

        
  );
};

export default ContactUs;

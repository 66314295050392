import './AboutUs.css';
import Img1 from '../assets/About.jpeg'
import Img2 from '../assets/About1.jpeg'


function AboutUs() {

  return (
    <div>
	 <h1 style={{ color: 'white'}}>About Us</h1>


<div className="intro-logo jumbo-bg">
        <h1>About Me</h1>
        </div>
      <section id="about">
      
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={Img1}
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <p className="mb-8 leading-relaxed" style={{padding: '10%',fontSize: '100%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>

          The story of Priya Chandra is one of endurance, spirit and persistent hardwork. From the humble beginnings of small town upbringing, she rose from nothing to what she is today.
          <br/>
          <br/>

          It was with a sheer stroke of destiny and passion for makeup and aesthetics that turned this corporate associate into a makeup professional she is today. <br/>  
          After graduating from the academy of the leading makeup artist Parul Garg herself, prepared this protégé into a full fledged beauty career aspirant. <br/>  
          She worked as a freelance makeup artist for two years where she got a gasp of what women desire, what they expect and how it is the job of makeup professional to help them envision their beauty.


            
          </p>
         
        </div>
       
      </div>

      
    </section>



    <section id="about">
      
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
    
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <p className="mb-8 leading-relaxed" style={{padding: '10%',fontSize: '100%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>

          The two years of freelancing followed by many big events such as, <br/>
          American Eagle launch party of which she was a part of and working with some of the best fashion designers such as Pallavi Mohan helped Priya prepare the blueprints of her future.<br/>
           With the help of a large social media fan base and some very loyal clientele, who still swear by her exceptional work, Priya led the foundation stone for her studio.<br/>

           <br/>
           <br/>
           Ever since, there has been no looking back for this 'Makeup queen' who has devoted her life into helping women across the globe become a better,<br/> 
           stronger and more confident version of themselves, all thanks to the artistry that comes from her fairy hands.

           <br/> <br/> 
           
           It was Priya's will and courage to choose for herself through the different stages of life that turned her into the person she is today. <br/>
           No career is made without sacrifices. Same holds true for her who gave up so much in life to be what she always wanted to be. <br/>
           In her own words- To be able to make a person feel beautiful on her day gives me immense pleasure.




            
          </p>
         
        </div>
        <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
        
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={Img2}
          />
        </div>
       
      </div>

    

      
    </section>
    <br/>
    </div>
  );
}

export default AboutUs;

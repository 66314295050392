import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Jewellery from '../components/Jewellery';

function JewelleryRentals() {
  return (
    <div className="App"  style={{ position: "relative" }}>
    <Header/>
   <Jewellery/>
    <Footer/>
    
    </div>
  );
}

export default JewelleryRentals;

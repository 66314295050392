// import React, { useState } from 'react';
// import Pop from './Pop';


// function Popup() {
//   const [isOpen, setIsOpen] = useState(false);

//   const togglePopup = () => {
//     setIsOpen(!isOpen);
//   }

//   return <div>
//     <input
//       type="button"
//       value="Click to Open Popup"
//       onClick={togglePopup}
//     />
//     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//     {isOpen && <Pop
//       content={<>
//         <b>Design your Popup</b>
//         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
//         <button>Test button</button>
//       </>}
//       handleClose={togglePopup}
//     />}
//   </div>
// }

// export default Popup;



import React, { useState, useEffect } from 'react';
import './Popup.css'
import "./ContactUs.css";
import anime, { set } from "animejs";
import Img2 from '../assets/Loc.png'
import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import validator from 'validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({

    heroContent: {
      backgroundColor: theme.palette.background.paper,
      padding: theme.spacing(8, 0, 6),
    },
  
  
  }));
  
  const validate = (values) => {
    const errors = {};
  
    if (!values.name) {
      errors.name = 'Required';
    } else if (values.name.length > 15) {
      errors.name = 'Must be 15 characters or less';
    }
  
    if (!values.message) {
      errors.message= 'Required';
    } else if (values.message.length < 10) {
      errors.message = 'Must contain atleast 10 characters';
    }
  
    if (!values.phone) {
      errors.phone = 'Required';
    } else if (!validator.isInt(values.phone) ) { 
      errors.phone= 'Phone number must only contain numbers';
    }
    else if(values.phone.length!==10){
      errors.phone="Phone number should contain 10 digits"
    }
  
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
  
    if (!values.location) {
      errors.location= 'Required';
    }
  
  
    return errors;
  };

function Popup() {
  const [isOpen, setIsOpen] = useState(true);

useEffect(()=> {
    console.log(isOpen, 'First')
},[])

const togglePopup = () => {
    setIsOpen(false);
    console.log(isOpen, "Hello")
  }


  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [dialogMessage,setdialogMessage]=React.useState('')

  const formik = useFormik({
         initialValues: {
           name: '',
           phone: '',
           email: '',
           location:'',
           message :''
         },
         validate,
         onSubmit: values => {
          
          
          fetch('https://j7obv3e358.execute-api.us-east-1.amazonaws.com/default/makeup-contactus', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'x-api-key' : '4GEfwRIHCq5aiwqz2ZLQ46INzW6LAYHU1pJkUTGf'
          
          
        },
       body: JSON.stringify(values)
      }) .then((response)=>response.json())
          .then(responsejson=>{
            
            if(responsejson=='success'){
             setIsOpen(false)
            
              setOpen(true);
      
            }
            else{
                setIsOpen(false)
          
              setOpen(true);
            }
          })
         },
       });


  return <div>
  {/* {isOpen &&  */}
  {isOpen ? 
    <div className="popup-box">
      <div className="popup-box-container">
        <span className="close-icon" onClick={togglePopup}>x</span>

        <div className="int jumbo-bg">
        <h1>Contact Us</h1>
        </div>

        <div style={{marinBottom: '5%'}}>
        <form onSubmit={formik.handleSubmit}>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="name" name="name" type="text" placeholder="Name" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.name}/>
                        {formik.touched.name && formik.errors.name ? (
                         <div>{formik.errors.name}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="email" name="email" type="text" placeholder="Email" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.email}/>
                        {formik.touched.email && formik.errors.email ? (
                          <div>{formik.errors.email}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" id="phone" name="phone" type="text" placeholder="Phone" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.phone}/>
                        {formik.touched.phone && formik.errors.phone ? (
                          <div>{formik.errors.phone}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mt-3">
                        <input class="form-control" type="text" name="location" id="location" placeholder="Location" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.location}/>
                        {formik.touched.location && formik.errors.location ? (
                          <div>{formik.errors.location}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group mt-3">
                        <input class="form-control" id="message" name="message" type="text" placeholder="Message" onChange={formik.handleChange}  onBlur={formik.handleBlur}  value={formik.values.message}/>
                        {formik.touched.message && formik.errors.message ? (
                          <div>{formik.errors.message}</div>
                           ) : null}
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <button type="submit" class="btn btn-danger-gradiant mt-3 mb-3  border-0 py-2 px-3"><span> SUBMIT NOW <i class="ti-arrow-right"></i></span></button>
                    </div>
                  </div>
                </form>
                </div>   

                
        {/* <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}} color="primary">
            Okay
          </Button>
          </DialogActions>
      </Dialog>  */}

        
      </div>
    </div>
    : null}
{/* } */}
  </div>
}

export default Popup;


import React,{useState, useEffect} from "react";
import Img1 from '../assets/LandingPage/1.jpeg'
import Img2 from '../assets/LandingPage/2.jpeg'
import Img3 from '../assets/LandingPage/3.png'

import Img4 from '../assets/Gallery1/1.jpeg'
import Img5 from '../assets/Gallery1/2.jpeg'
import Img6 from '../assets/Gallery1/5.jpeg'
import Img7 from '../assets/LandingPage/10.png'
import Img8 from '../assets/Gallery1/11.jpeg'
import Img9 from '../assets/Gallery1/12.jpeg'

import ReactPlayer from 'react-player';



import '../styles/Landing.css'
//  import Popup from 'react-animated-popup'


import { useFormik } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import validator from 'validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Popup from "../components/Popup";





const useStyles = makeStyles((theme) => ({

  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },


}));

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required';
  } else if (values.name.length > 15) {
    errors.name = 'Must be 15 characters or less';
  }

  if (!values.message) {
    errors.message= 'Required';
  } else if (values.message.length < 10) {
    errors.message = 'Must contain atleast 10 characters';
  }

  if (!values.phone) {
    errors.phone = 'Required';
  } else if (!validator.isInt(values.phone) ) { 
    errors.phone= 'Phone number must only contain numbers';
  }
  else if(values.phone.length!==10){
    errors.phone="Phone number should contain 10 digits"
  }

  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.location) {
    errors.location= 'Required';
  }


  return errors;
};




function LandingPage() {
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  
  const [dialogMessage,setdialogMessage]=React.useState('')

  const formik = useFormik({
         initialValues: {
           name: '',
           phone: '',
           email: '',
           location:'',
           message :''
         },
         validate,
         onSubmit: values => {
          
          fetch('https://kxuts8atmk.execute-api.us-east-1.amazonaws.com/default/ContactUs_Go2ways', {
        method: 'POST',
        headers: {
           Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          
          
        },
       body: JSON.stringify(values)
      }) .then((response)=>response.json())
          .then(responsejson=>{
            if(responsejson=='success'){
             
              setdialogMessage("Query Submitted Successfully ")
              setOpen(true);
      
            }
            else{
             
              setdialogMessage("Please check the details and try again")
              setOpen(true);
            }
          })
         },
       });

       const [visible, setVisible] = useState(false)

  useEffect(()=> {
    setTimeout(() => {
    setVisible(true)
    // document.body.style.overflow = "hidden";
  }, 2000);
  },[])


  



  return (
    <div>
    <div>
    {visible? 
    <Popup visible={visible}/>
    : null}
    </div>


      <section>
        <div class="container">
          <div class="left"></div>
          <div class="right">
            <div class="content">
              <h1 style={{ fontSize: '3em',fontWeight: '900',color: "white",fontFamily: 'Dancing Script'}}>PRIYA CHANDRA</h1>
              <p style={{}}>Makeup Artist</p>
            </div>
          </div>
        </div>
      </section>


   

      <div class="container1">
      <div className="offer jumbo-bg">
        <h1>Offering</h1>
        </div>
      <div className="Card">
     
    <div className="box" >
          <img src={Img1} alt="" />
          <div className="intro">
        <h1>Bridal Makeup</h1>
        </div>
          <p style={{fontSize: '85%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>Shine bright like a diamond. From Subtle undertones to bold eyes, Priya understands what the bride wants on her special day and customizes the makeup so that her every bride looks a class apart. </p>
    </div>
    <div className="box" >
          <img src={Img2} alt="" />
          <div className="intro">
        <h1>Reception Makeup</h1>
        </div>
          <p style={{fontSize: '85%', padding: '8%', color: '#6d6d6e',  fontStyle: 'italic'}}>So it's your Reception day and you are looking for a glam makeup look? So, your search ends here as Priya's Reception Makeup will ensure you look the most beautiful and be the centre of attraction on your special day.</p>
    </div>
    <div className="box" >
          <img src={Img3} alt="" />
          <div className="intro">
        <h1>Occasional Makeup</h1>
        </div>
          <p style={{fontSize: '85%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>Looking for a perfect party makeup with either it be your Mehendi or your Engagement. Priya will be offering you with best look, which is subtle and elegant in the same place with very less effort.  </p>
    </div>
    </div>
      </div>




      <div className="intClient jumbo-bg">
        <h1>Client Testimony</h1>
        </div>


      <div>
      <figure class="snip1533">
  <figcaption>
    <blockquote>
      <p>Loved the makeup look that Mam had given me. You will not be disappointed. A high range of beauty products that fits your need. Highly recommended.</p>
    </blockquote>
    <h3>- Rashmi Sharma</h3>
  </figcaption>
</figure>
<figure class="snip1533">
  <figcaption>
    <blockquote>
      <p> I was totally satisfied with my look.. My mom also got her party makeup by Priya Mam and she just added more grace to her beauty.
Thank you so much dear... It was a great experience and everybody appreciated your work..
All the best
</p>
    </blockquote>
    <h3>- Mansi</h3>
  </figcaption>
</figure>
<figure class="snip1533">
  <figcaption>
    <blockquote>
      <p>First of all thank you so much for the wonderful makeup done by Priya Chandra for my reception. It really made my day, she literally has some magic in her hands.  </p>
    </blockquote>
    <h3>- Shikha Chauhan</h3>
  </figcaption>
</figure>
</div>
<br/>





<div className="intGallery jumbo-bg">
        <h1>Gallery</h1>
        </div>



      <div class="container1">
      <div className="Card">
    <div className="box" >
          <img src={Img4} alt="" />
    </div>
    <div className="box" >
          <img src={Img5} alt="" />
    </div>
    <div className="box" >
          <img src={Img6} alt="" />
    </div>
    <div className="box" >
          <img src={Img7} alt="" />
    </div>
    <div className="box" >
          <img src={Img8} alt="" />
    </div>
    <div className="box" >
          <img src={Img9} alt="" />
    </div>
    </div>
      </div>




      <section5>
        <div class="videoContainer">
          <ReactPlayer  controls url="https://www.youtube.com/watch?v=2pG6dYwCAgc" />
        </div>
      </section5>
      
     

      <Dialog
        open={open}
        onClose={()=>{setOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Message"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{setOpen(false)}} color="primary">
            Okay
          </Button>
          </DialogActions>
      </Dialog>
     
 


    </div>


  );
}

export default LandingPage;


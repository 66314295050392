import './Jewellery.css';
import JwelleryImg from '../assets/Jwellery.png'


function Jewellery() {
  return (
//     <div>
//     <h1 style={{ color: 'white'}}>About Us</h1>
// <main class="main">
// 	<article class="entry entry-lede">
// 		<img class="entry-img" src={JwelleryImg} alt="Priya Chandra" />
// 		<div class="entry-content">
// 		<div className="intro-logo jumbo-bg">
//         <h1>Jewellery Rental</h1>
//         </div>
			// <p style={{fontSize: '100%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>“An art is an obsession, continously compelling the artist to create a Masterpiece. From minimalist to extravagant, my goal is to create designs that truly enhance the personalities of my clients that are full of taste, charm and sophistication”</p>
      // <p style={{fontSize: '100%', color: '#6d6d6e',  fontStyle: 'italic'}}>Other than makeup looks we also provide Jwellery on rental basis where you could complete your overall look and flaunt it on your D-day </p>
      // <p style={{fontSize: '100%', color: '#6d6d6e',  fontStyle: 'italic'}}>We enable customers to rent out exciting designer and luxury pieces  as many times as they want and as much as they want for one flat price. </p>

// 		</div>
// 	</article>   
// </main>
//     </div>





<div>
	 <h1 style={{ color: 'white'}}>Jewellery Rental</h1>


<div className="intro-logo jumbo-bg">
        <h1>Jewellery Rental</h1>
        </div>
      <section id="about">
      
      <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
          <img
            className="object-cover object-center rounded"
            alt="hero"
            src={JwelleryImg}
          />
        </div>
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <p style={{padding: '10%',fontSize: '100%', padding: '5%', color: '#6d6d6e',  fontStyle: 'italic'}}>“An art is an obsession, continously compelling the artist to create a Masterpiece. From minimalist to extravagant, my goal is to create designs that truly enhance the personalities of my clients that are full of taste, charm and sophistication”<br/><br/>
Other than makeup looks we also provide Jwellery on rental basis where you could complete your overall look and flaunt it on your D-day. <br/><br/>
We enable customers to rent out exciting designer and luxury pieces  as many times as they want and as much as they want for one flat price. </p>

         
        </div>
       
      </div>

      
    </section>
    <br/>
    </div>
  );
}

export default Jewellery;

import '../App.css';
import GalleryCard from '../components/Gallery.js'
import Header from '../components/Header';
import Footer from '../components/Footer';

function Gallery() {
  return (
    <div className="App"  style={{ position: "relative" }}>
    <Header/>
    <GalleryCard/>
    <Footer/>
    </div>
  );
}

export default Gallery;

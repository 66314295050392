import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Scroll from '../components/Scroll';
import BrandsWeUse from '../components/BrandsWeUse';


function Brands() {
  return (
    <div className="App"  style={{ position: "relative" }}>
    <Header />
    <BrandsWeUse/>
    <Footer/>
   
    </div>
  );
}

export default Brands;

import React from 'react';
import Logo1 from '../assets/Logo1.png'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';

export default function App() {
  return (
    <MDBFooter style={{backgroundColor: "#9c0419"}} className='text-center text-lg-start text-muted'>
      {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section> */}

      {/* <section className=''> */}
        <MDBContainer className='text-white'>
          <MDBRow className='mt-3'>
            <MDBCol md="2" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                {/* Priya Chandra */}
                <a href="/" className="logo-link">
              <img src={Logo1} height="80%"/>
            </a>
              </h6>
              <p class="text-white">
                {/* Here you can use rows and columns to organize your footer content. Lorem ipsum dolor sit amet,
                consectetur adipisicing elit. */}
                "There are endless ways to enhance beauty. You never know when you will find your perfect lipstick - unless you keep experimenting."
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='text-white'>
              {/* <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a href='#!' className='text-white'>
                  Angular
                </a>
              </p>
              <p>
                <a href='#!' className='text-white'>
                  React
                </a>
              </p>
              <p>
                <a href='#!' className='text-white'>
                  Vue
                </a>
              </p>
              <p>
                <a href='#!' className='text-white'>
                  Laravel
                </a>
              </p> */}
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className='text-white'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
                <a href='/about-us' className='text-white'>
                  About Me
                </a>
              </p>
              <p>
                <a href='/gallery' className='text-white'>
                  Look Book
                </a>
              </p>
              <p>
                <a href='/brands' className='text-white'>
                  Brands We Use
                </a>
              </p>
              <p>
                <a href='/jewellery' className='text-white'>
                Jewellery Rental
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='text-white'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                162, Bahawalpur CGHS society, Dwarka-4, Delhi-110077
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                makeupbypriya14@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 8287623582
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 8010387436
              </p>
              <p>
              <a href="https://www.instagram.com/priyachandra_mua/?igshid=ZjA0NjI3M2I%3D" style={{color:'white'}}>
                <MDBIcon icon="camera-retro" className="me-3" /> @priyachandra_mua
                </a>
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      {/* </section> */}

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)', color: 'white' }}>
       
      </div>
    </MDBFooter>
  );
}
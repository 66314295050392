import React from "react";
import ResponsiveMenu from "react-responsive-navbar";
import { Close } from "styled-icons/material/Close";
import { Menu } from "styled-icons/material/Menu";
import './Header.css';
import Logo1 from '../assets/Logo1.png'


export default function(props) {
  return (
    <header className="header">
      <div className="wrap">
        <header className="logo">
          <h1 className="logo-title">
            <a href="/" className="logo-link">
              <img src={Logo1} height="80%"/>
            </a>
          </h1>
        </header>
        <nav className="menu">
          <ResponsiveMenu
            menuOpenButton={
              <div className="menu hamburger-menu menu-btn">
                <Menu size={32} />
              </div>
            }
            menuCloseButton={
              <div className="menu hamburger-menu menu-btn">
                <Close size={32} />
              </div>
            }
            changeMenuOn="900px"
            menu={
              <ul className="menu-list">
                <li className="menu-item is-active menu-item--play">
                  <a href="/about-us" className="menu-link">
                    About Me
                  </a>
                </li>
                <li className="menu-item is-active menu-item--play">
                  <a href="/gallery" className="menu-link">
                    Look Book
                  </a>
                </li>
                <li className="menu-item is-active menu-item--play">
                  <a href="/brands" className="menu-link">
                    Brands We Use
                  </a>
                </li>
                <li className="menu-item is-active menu-item--play">
                  <a href="/jewellery" className="menu-link">
                    Jewellery Rental
                  </a>
                </li>
                <li className="menu-item is-active menu-item--play">
                  <a href="contact-us" className="menu-link">
                    Contact Us
                  </a>
                </li>
              </ul>
            }
          />
        </nav>
      </div>
    </header>
  );
}

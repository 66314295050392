import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import AboutUs from '../components/AboutUs';

function About() {
  return (
    <div className="App"  style={{ position: "relative" }}>
    <Header/>
    <AboutUs/>
    <Footer/>
    
    </div>
  );
}

export default About;

import * as React from "react";
import {
    BrowserRouter,
    Switch,
    Route,
  } from "react-router-dom";
import Gallery from "../pages/Gallery";
import Home from "../pages/Home";
import About from "../pages/About";
import JewelleryRentals from "../pages/JewelleryRental";
import Brands from "../pages/Brands";
import Contact from "../pages/Contact";

import Popup from "../components/Popup";


export default function Route1() {
  return (
    <div>
      <BrowserRouter>
      <Switch>
      <Route exact path="/" component={Home}/>  
      <Route exact path="/about-us" component={About}/>    
      <Route path="/gallery" component={Gallery}/> 
      <Route path="/brands" component={Brands}/>
      <Route path="/jewellery" component={JewelleryRentals}/>
      <Route path="/contact-us" component={Contact}/>

      <Route path="/popup" component={Popup}/>
      </Switch>
      </BrowserRouter>
    </div>
  );
}

import { useState } from "react";
import './Gallery.css'

import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import photos from "./photos";


//let photos = [{src: '../assets/Gallery/11.JPG', width: 1080, height: 780,images:[{src: '../assets/Gallery/11.JPG', width: 1080, height: 780},{src: '../assets/Gallery/12.JPG', width: 1080, height: 780}]},{src: '../assets/Gallery/12.JPG', width: 1080, height:780,images:[{src: '../assets/Gallery/11.JPG', width: 3840, height: 2773.3333333333335},{src: '../assets/Gallery/12.JPG', width: 3840, height: 2773.3333333333335}]}]
const slides = photos.map(({ src, width, height, images }) => (

    {
    width,
    height,
    srcSet: images.map((image) => ({
        src: src,                                 //image.src,
        width: 1078,
        height: 500,
    })),
}));


const GalleryCard = () => {
    const [index, setIndex] = useState(-1);

    return (
        <div>
        <h1 style={{ color: 'white'}}>About Us</h1>

<div className="intro-logo jumbo-bg">
        <h1>Look Book</h1>
        </div>

<div style={{margin:'10%'}}>
            <PhotoAlbum
                photos={photos}
                layout="rows"
                targetRowHeight={450}
                onClick={(event, photo, index) => setIndex(index)}
            />

            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
            </div>
        </div>
    );
};

export default GalleryCard;

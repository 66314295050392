import { useEffect, useState } from "react";
import "./BrandsWeUse.css";
import anime from "animejs";
import Brand1 from '../assets/Brands/1.png'
import Brand2 from '../assets/Brands/2.png'
import Brand3 from '../assets/Brands/3.png'
import Brand4 from '../assets/Brands/4.png'
import Brand5 from '../assets/Brands/5.png'
import Brand6 from '../assets/Brands/6.png'
import Brand7 from '../assets/Brands/7.png'
import Brand8 from '../assets/Brands/8.png'
import Brand9 from '../assets/Brands/9.png'
import Brand10 from '../assets/Brands/10.png'
import Brand11 from '../assets/Brands/11.png'
import Brand12 from '../assets/Brands/12.png'
import Brand13 from '../assets/Brands/13.png'
import Brand14 from '../assets/Brands/14.png'
import Brand15 from '../assets/Brands/15.png'



const BrandsWeUse = () => {


  let cardAnimation = () => {
    anime({
      targets: ".Card",
      translateX: [-2000, 0],
      direction: "normal",
      easing: "linear",
      delay: 500,
      opacity: [0, 1]
    });
  };

  useEffect(() => {
    cardAnimation();
  });


  return (
    <div>
 <h1 style={{ color: 'white'}}>About Us</h1>
<div className="intro-logo jumbo-bg">
        <h1>Brands We Use</h1>
        </div>
    <div className="Card">
    <div className="box" >
          <img src={Brand1} alt="" />
    </div>
    <div className="box" >
          <img src={Brand2} alt="" />
    </div>
    <div className="box" >
          <img src={Brand3} alt="" />
    </div>
    <div className="box" >
          <img src={Brand4} alt="" />
    </div>
    <div className="box" >
          <img src={Brand5} alt="" />
    </div>
    <div className="box" >
          <img src={Brand6} alt="" />
    </div>
    <div className="box" >
          <img src={Brand7} alt="" />
    </div>
    <div className="box" >
          <img src={Brand8} alt="" />
    </div>
    <div className="box" >
          <img src={Brand9} alt="" />
    </div>
    <div className="box" >
          <img src={Brand10} alt="" />
    </div>
    <div className="box" >
          <img src={Brand11} alt="" />
    </div>
    <div className="box" >
          <img src={Brand12} alt="" />
    </div>
    <div className="box" >
          <img src={Brand13} alt="" />
    </div>
    <div className="box" >
          <img src={Brand14} alt="" />
    </div>
    <div className="box" >
          <img src={Brand15} alt="" />
    </div>
  
    </div>
    </div>
  );
};

export default BrandsWeUse;

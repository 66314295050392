import { Animator, batch, MoveOut, ScrollContainer,ScrollPage , Sticky, StickyIn, FadeIn, ZoomIn, Move, Fade} from "react-scroll-motion";
import '../App.css'


const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
const FadeUp = batch(Fade(), Sticky(), Move())


function Scroll (){


    return(
        <div>
        <ScrollContainer>
        <ScrollPage page={0}>
        <Animator animation={batch(Sticky(),Fade(), MoveOut(0, -200))}>
            <h1>Let me show you scrool animation</h1>
        </Animator>
        </ScrollPage>
      

      
        <ScrollPage page={1}>
        <Animator animation={ZoomInScrollOut}>
            <h1>Fade scroll out</h1>
        </Animator>
        </ScrollPage>

        <ScrollPage page={2}>
        <Animator animation={FadeUp}>
            <h1>Fade scroll out</h1>
        </Animator>
        </ScrollPage>

        {/* <ScrollPage page={3}>
        <Animator animation={FadeUp}>
            <h1>Fade scroll out</h1>
        </Animator>
        </ScrollPage> */}

        </ScrollContainer>
        </div>

        
    )
}

export default Scroll;
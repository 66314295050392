import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactUs from '../components/ContactUs';


function Contact() {
  return (
    <div>
    <Header />
    <ContactUs/>
    <Footer/>
   
    </div>
  );
}

export default Contact;

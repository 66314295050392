import '../App.css';
import Header from '../components/Header';
import Footer from '../components/Footer';
import LandingPage from '../screens/LandingPage';
import Scroll from '../components/Scroll';


function Home() {
  return (
    <div className="App"  style={{ position: "relative" }}>
    <Header />
    <LandingPage />
    <Footer/>
   
    </div>
  );
}

export default Home;

import './App.css';
import Route1 from './routes/routes';

function App() {
  return (
    <div className="App">
    <Route1/>
    </div>
  );
}

export default App;

// { id: "20.JPG", width: 1080, height: 768 },

const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const unsplashLink = (id: string, width: number, height: number) =>
    `require(../assets/Gallery1/${id}`

//`https://source.unsplash.com/${id}/${width}x${height}`;

const unsplashPhotos = [
    { id: "1.jpeg", width: 2880, height: 3968 },
    { id: "2.jpeg", width: 2880, height: 3968 },
    { id: "3.jpeg", width: 2880, height: 3968 },
    { id: "4.jpeg", width: 2880, height: 3968 },
    { id: "5.jpeg", width: 2880, height: 3968 },
    { id: "6.jpeg", width: 2880, height: 3968},
    { id: "7.jpeg", width: 2880, height: 3968},
    { id: "8.jpeg", width: 2880, height: 3968},
    { id: "9.jpeg", width: 2880, height: 3968},
    { id: "10.jpeg", width: 2880, height: 3968 },
    { id: "11.jpeg", width: 2880, height: 3968 },
    { id: "12.jpeg", width: 2880, height: 3968 },
    { id: "13.jpeg", width: 2880, height: 3968 },
    { id: "14.jpeg", width: 2880, height: 3968 },
    { id: "15.jpeg", width: 2880, height: 3968 },
    { id: "16.jpeg", width: 2880, height: 3968 },
    { id: "17.jpeg", width: 2880, height: 3968 },
    { id: "18.jpeg", width: 2880, height: 3968 },
    { id: "19.jpeg", width: 2880, height: 3968 },
    { id: "20.jpeg", width: 2880, height: 3968 },
    { id: "21.jpeg", width: 2880, height: 3968 },
    // { id: "twukN12EN7c", width: 1080, height: 694 },
];

const photos = unsplashPhotos.map((photo) => {
    const width =  breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: require("../assets/Gallery1/"+photo.id),
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src:require("../assets/Gallery1/"+photo.id),                
                width: photo.height,
                height:photo.width
            };
        }),
    };
});

export default photos;
